<template>
  <div>
    <div class="banner_info" style="background: url(images/7_02.jpg) center no-repeat;background-size: cover;">
      <img src="images/7_03.png">
    </div>
    <div class="infopage">
      <div class="container clearfix">
        <div class="left">
          <div class="box">
            <dl>
              <dt>通知公告</dt>
              <dd>Notice announcement</dd>
            </dl>
            <ul>
              <li><a href="#">中联认证中心（北京）</a></li>
              <li><a href="#">关于举办GB/T 19001-2016</a></li>
              <li><a href="#">关于在新型冠状病毒感染肺</a></li>
              <li><a href="#">重要通知</a></li>
              <li><a href="#">关于举办ISO50001:2018能</a></li>
              <li><a href="#">关于举办GB/T 24001-2016</a></li>
              <li><a href="#">关于推动企业“科技创新</a></li>
            </ul>
          </div>
          <div class="box">
            <dl>
              <dt>法律法规</dt>
              <dd>laws and regulations</dd>
            </dl>
            <ul>
              <li><a href="#">中华人民共和国计量法实施</a></li>
              <li><a href="#">中华人民共和国计量法</a></li>
              <li><a href="#">中华人民共和国大气污染防</a></li>
              <li><a href="#">中华人民共和国种子法</a></li>
              <li><a href="#">中华人民共和国网络安全法</a></li>
              <li><a href="#">中华人民共和国产品质量法</a></li>
              <li><a href="#">中华人民共和国进出口商品</a></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="box">
            <div class="title flex">
              <div class="flex_item"><b>公开文件管理</b></div>
              <a href="#" class="more">更多</a>
            </div>
            <div class="bd">
              <ul class="newslist" v-if="columnData.articleList && columnData.articleList.length>0">
                <li v-for="item in columnData.articleList" :key="item.id">
                  <div class="flex_item"><a href="#">{{item.title}}</a></div>
                  <span>{{item.publishTime}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicFile",
  data(){
    return {
      columnData:[]
    }
  },
  mounted() {
    this.findColumnData()
  },
  methods:{
    async findColumnData(){
      let res = await this.$api.findData({columnName:'公开文件'});
      this.columnData = res.data.data || [];
      console.log(this.columnData,'--');
    },
  }
}
</script>

<style scoped>

</style>
